import { Link } from "react-router-dom";
import { styled } from "styled-components";


export const LinkText = styled(Link)`
text-decoration: none;
color:white;
font-size:14px;

font-family: 'Manrope', sans-serif;

`