import React from "react";
import { MusicContainer } from "./styles";
import { NavBar } from "../../components/navbar/navbar.component";


const Music = () =>{
    return(
        <MusicContainer>
            <NavBar/>
            mdmmmmmmmmmm
        </ MusicContainer>
    )
}

export default Music;